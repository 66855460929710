import { Container, TabLabel } from "./styles";
import type { TabProps } from "./types";
import Tag from "../../Tag";

export default function Tab({ className, label, stats, color, backgroundColor, active, onClick }: TabProps): JSX.Element {
	return (
		<Container $active={active} className={className} onClick={onClick}>
			<TabLabel>
				{label}
				{stats && (
					<>
						&nbsp; <Tag label={stats} color={color} backgroundColor={backgroundColor} small={true} />
					</>
				)}
			</TabLabel>
		</Container>
	);
}
