import React, { memo } from "react";
import { ThemeProvider } from "styled-components";
import { Toaster, toast } from "react-hot-toast";

import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import Toast from "./Toast";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { EventEmitter } from "@roambee/client-utility";

const Snackbar = () => {

	React.useEffect(() => {
		const listener = (eventData) => {
			toast((t) => <Toast {...eventData} id={t.id} />, {
				position: eventData.position
			});
		};

		EventEmitter.addListener("showSnackbar", listener);

		return () => {
			EventEmitter.removeListener("showSnackbar", listener);
		};
	}, []);

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Toaster
				position="bottom-center"
				gutter={16}
				toastOptions={{
					duration: 4000,
					style: {
						backgroundColor: "transparent",
						boxShadow: "none",
						maxWidth: "100%",
						padding: 0,
						marginBlockEnd: 1
					}
				}}
			/>
		</ThemeProvider>
	);
};

export default memo(Snackbar);
