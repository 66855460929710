import { Close } from "@carbon/icons-react";
import { Drawer } from "@material-ui/core";
import styled from "styled-components";

const drawerWidth = 320;

export const Container = styled.div`
	position: relative;
	display: flex;
	width: fit-content;
`;
export const RightPanel = styled(Drawer)<{ width?: number }>`
	width: ${(props) => (props.width ? `${props.width}px` : `${drawerWidth}px`)};
	flex-shrink: 0;

	& .MuiDrawer-paper {
		width: ${(props) => (props.width ? `${props.width}px` : `${drawerWidth}px`)};
		border-left: 0;
		box-shadow: ${({ theme }) => theme.shadows.shadow12};
	}
`;
export const ContentHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
export const CloseIconWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;
export const CloseButton = styled(Close)`
	cursor: pointer;
`;
export const ContentWrapper = styled.div`
	padding: 1rem;
`;
export const Content = styled.div``;
