import light from "../../styles/themes/light";
import { ChartProps, DataItem } from "./types";

export default function useLogic<T extends DataItem>({ data }: ChartProps<T>) {
	const dataItems = data[0];
	const dataKeys: string[] = dataItems ? Object.keys(dataItems).slice(1) : [];

	const colors = [
		{
			original: light.colors.yellowBee200,
			hover: light.colors.yellowBee400
		},
		{
			original: light.colors.oceanicBlue200,
			hover: light.colors.oceanicBlue400
		},
		{
			original: light.colors.purpleSunset400,
			hover: light.colors.purpleSunset600
		}
	];

	return {
		dataKeys,
		colors
	};
}
