import { ThemeProvider } from "styled-components";
import { BarDatum, BarTooltipProps, ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine, Serie } from "@nivo/line";
import { useTooltip, Tooltip } from "@nivo/tooltip";
import { timeFormat } from "d3-time-format";
import { format } from "d3-format";

import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { ChartProps, DataItem } from "./types";
import { Card, ChartWrapper, ColorLabel, Item, Legend, ValueItem } from "./styles";
import useLogic from "./logic";

const CustomBarTooltip = ({ id, value, color }: BarTooltipProps<BarDatum>) => {
	return (
		<Card>
			<ValueItem $bold>
				<ColorLabel style={{ backgroundColor: color }} />
				{id}&nbsp;:&nbsp;<Item $bold>{value}</Item>
			</ValueItem>
		</Card>
	);
};

// CustomTooltip component
const CustomTooltip = ({ slice }) => {
	return (
		<Card>
			{slice.points.map((point) => (
				<ValueItem $bold key={point.id}>
					<ColorLabel style={{ backgroundColor: point.color }} />
					{point.serieId}&nbsp;:&nbsp;<Item $bold>{point.data.yFormatted}</Item>
				</ValueItem>
			))}
		</Card>
	);
};

export default function Chart<T extends DataItem>({ data, xDataKey, yDataKeys, xDataType, chartType, showLegend = true, height }: ChartProps<T>): JSX.Element {
	const { colors } = useLogic({
		data,
		xDataKey,
		yDataKeys,
		xDataType,
		chartType
	});

	const renderChart = () => {
		switch (chartType) {
			case "bar": {
				return (
					<ResponsiveBar
						animate
						data={data as BarDatum[]}
						keys={yDataKeys as string[]}
						indexBy={xDataKey as string}
						enableLabel={false}
						enableGridX={false}
						enableGridY={false}
						padding={0.3}
						innerPadding={0}
						valueScale={{ type: "linear" }}
						margin={{ top: 5, right: 5, bottom: 75, left: 55 }}
						colors={colors.map((color) => color.original)}
						axisBottom={{
							format: (value: any) => (xDataType === "time" ? timeFormat("%B %d, %Y %H:%M")(value) : value),
							tickSize: 0,
							tickPadding: 10,
							tickRotation: -45,
							legend: "", // xDataKey as string
							legendPosition: "middle",
							legendOffset: 32
						}}
						axisLeft={{
							legend: yDataKeys
								.join(", ")
								.split("_")
								.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
								.join(" "),
							legendPosition: "middle",
							legendOffset: -40,
							tickValues: 4,
							tickSize: 0,
							tickPadding: 12
						}}
						theme={{
							textColor: "#908E9A",
							fontSize: 10,
							grid: {
								line: {
									stroke: "#E8E7ED",
									strokeWidth: 1
								}
							}
						}}
						tooltip={CustomBarTooltip} // Use the custom tooltip component
					/>
				);
			}
			case "line": {
				const series = yDataKeys.map((key) => ({
					id: key,
					data: data.map((item) => ({
						x: item[xDataKey] as string | number | Date,
						y: item[key] as number
					}))
				}));
				return (
					<>
						<ResponsiveLine
							animate
							useMesh
							enableArea
							curve="monotoneX"
							data={series as Serie[]}
							enableGridX={false}
							enableGridY={false}
							margin={{ top: 5, right: 5, bottom: 75, left: 55 }}
							colors={colors.map((color) => color.original)}
							axisBottom={{
								format: (value: any) => (xDataType === "time" ? timeFormat("%B %d, %Y %H:%M")(value) : value),
								tickSize: 0,
								tickPadding: 10,
								tickRotation: -45,
								legend: "", // xDataKey as string
								legendPosition: "middle",
								legendOffset: 32
							}}
							axisLeft={{
								legend: yDataKeys
									.join(", ")
									.split("_")
									.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
									.join(" "),
								legendPosition: "middle",
								legendOffset: -40,
								tickValues: 4,
								tickSize: 0,
								tickPadding: 12
							}}
							theme={{
								textColor: "#908E9A",
								fontSize: 10,
								grid: {
									line: {
										stroke: "#E8E7ED",
										strokeWidth: 1
									}
								}
							}}
							defs={[
								{
									colors: [
										{
											color: "inherit",
											offset: 0
										},
										{
											color: "inherit",
											offset: 100,
											opacity: 0
										}
									],
									id: "gradientA",
									type: "linearGradient"
								}
							]}
							fill={[
								{
									id: "gradientA",
									match: "*"
								}
							]}
							enableSlices="x"
							sliceTooltip={CustomTooltip} // Use the custom tooltip component
						/>
						<Tooltip />
					</>
				);
			}
			default: {
				return null;
			}
		}
	};

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<ChartWrapper height={height}>{renderChart()}</ChartWrapper>
			{showLegend && (
				<Legend>
					{yDataKeys.map((el: string, index: number) => (
						<ValueItem key={index}>
							<ColorLabel style={{ backgroundColor: colors[index]?.original }} />{" "}
							{el
								.split("_")
								.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
								.join(" ")}
						</ValueItem>
					))}
				</Legend>
			)}
		</ThemeProvider>
	);
}
