import { AddAlt } from "@carbon/icons-react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { DropdownButton, IconContainer, TabsLi } from "./styles";
import Tab from "./Tab";
import type { TabProps } from "./Tab/types";
import type { TabsProps } from "./types";
import IconButton from "../IconButton";
import { Stack } from "@mui/material";
import { StyledMuiTabs } from "./styles";
export default function Tabs({ className, tabs, hideAddTab, addTabHandler, value }: TabsProps): JSX.Element {
	const addTab = [
		{
			label: "New Tab 1",
			onClick: () => alert("New Tab 1 clicked")
		},
		{
			label: "New Tab 2",
			onClick: () => alert("New Tab 2 clicked")
		},
		{
			label: "New Tab 3",
			onClick: () => alert("New Tab 3 clicked")
		}
	];

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Stack className={className} direction="row">
				{value && (
					<StyledMuiTabs
						value={value}
						variant="scrollable"
						scrollButtons="auto"
					>
						{tabs.map((tab: TabProps, index: number) => (
							<Tab key={index} label={tab.label} value={tab.value} active={tab.active} onClick={tab.onClick} />
						))}
					</StyledMuiTabs>
				)}
				{!hideAddTab && (
					<TabsLi>
						<IconContainer>
							{addTabHandler ? (
								<IconButton icon={<AddAlt />} onClick={addTabHandler} ariaLabel={""} size={"small"} variant="no-bg" />
							) : (
								<DropdownButton options={addTab} customIcon={<AddAlt />} />
							)}
						</IconContainer>
					</TabsLi>
				)}
			</Stack>
		</ThemeProvider>
	);
}
