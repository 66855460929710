import NotificationNew from "../../assets/icons/notification-new.svg";
import { Notification, Search, Switcher } from "@carbon/icons-react";

import useLogic from "./logic";
import { SearchModal } from "./SearchModal";
import { Avatar, Container, Initial, InputSearchGlobal, MenuButton, Name, Notifications, ProfileContainer, SearchButton, Title } from "./styles";
import type { GlobalSearchProps } from "./types";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import { Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import SelectSearch from "../SelectSearch";

export default function GlobalSearch({ className, data, id, title, placeholder, quick, recent, newNotification, showNotification, showSearch, showLogo, logo, menuItems, user, account, accounts, handleSwitchAccount }: GlobalSearchProps): JSX.Element {
	const { isMobile, isOpenModal, onCloseSearchModal, onOpenSearchModal } = useLogic();
	const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(null);

	const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElMenu(event.currentTarget);
	};

	const handleCloseMenu = (item) => {
		setAnchorElMenu(null);
		try {
			item.clickHandler();
			// eslint-disable-next-line no-empty
		} catch (error) { }
	};
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				{showLogo ? <img src={logo} alt="logo" /> : <></>}
				<Title>{title}</Title>
				{showSearch ? (
					isMobile ? (
						<>
							<SearchButton icon={<Search />} variant="no-bg" ariaLabel="search button" size="small" onClick={onOpenSearchModal} />
							<SearchModal isOpen={isOpenModal} onClose={onCloseSearchModal} id={id} placeholder={placeholder} data={data} quick={quick} recent={recent} />
						</>
					) : (
						<InputSearchGlobal id={id} placeholder={placeholder} data={data} quick={quick} recent={recent} />
					)
				) : (
					<></>
				)}

				{showNotification ? <Notifications>{!newNotification ? <Notification /> : <NotificationNew />}</Notifications> : <></>}

				<ProfileContainer>
					<SelectSearch value={account} handleValueChange={handleSwitchAccount} label={""} options={accounts ? accounts : []} disabled={false} id="CustomerSelect" />
					<Avatar>
						<Initial $bold>{user?.name ? user?.name[0] : ""}</Initial>
					</Avatar>
				</ProfileContainer>

				<MenuButton icon={<Switcher />} ariaLabel="Menu" onClick={handleOpenMenu} />
				<Menu
					sx={{ mt: "45px" }}
					id="menu-appbar"
					anchorEl={anchorElMenu}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right"
					}}
					keepMounted
					transformOrigin={{
						vertical: "top",
						horizontal: "right"
					}}
					open={Boolean(anchorElMenu)}
					onClose={handleCloseMenu}
				>
					{menuItems.length &&
						menuItems.map((item: any) => (
							<MenuItem key={item.lable} onClick={() => handleCloseMenu(item)}>
								<Typography textAlign="center">{item.lable}</Typography>
							</MenuItem>
						))}
				</Menu>
			</Container>
		</ThemeProvider>
	);
}
