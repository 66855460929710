import { ThemeProvider } from "styled-components";
import { BarDatum, BarTooltipProps, ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveLine, Serie } from "@nivo/line";
import { Tooltip } from "@nivo/tooltip";
import { timeFormat } from "d3-time-format";

import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { ChartProps, DataItem } from "./types";
import { Card, ChartWrapper, ColorLabel, Item, Legend, ValueItem, PieChartWrapper } from "./styles";
import useLogic from "./logic";
import { useState, useEffect, useMemo } from "react";

const CustomBarTooltip = ({ id, value, color, label }: BarTooltipProps<BarDatum>) => {
	return (
		<Card>
			<ValueItem $bold $active={true}>
				<ColorLabel style={{ backgroundColor: color }} />
				{label.replace(/_/g, ' ')}&nbsp;:&nbsp;<Item $bold>{value}</Item>
			</ValueItem>
		</Card>
	);
};

// CustomTooltip component
const CustomTooltip = ({ slice }) => {
	return (
		<Card>
			{slice.points.map((point) => (
				<ValueItem $bold $active={true} key={point.id}>
					<ColorLabel style={{ backgroundColor: point.color }} />
					{point.serieId}&nbsp;:&nbsp;<Item $bold>{point.data.yFormatted}</Item>
				</ValueItem>
			))}
		</Card>
	);
};

export default function Chart<T extends DataItem>({ data, xDataKey, yDataKeys, xDataType, uniqueXValues, chartType, showLegend = true, height, isPreview = false }: ChartProps<T>): JSX.Element {
	const { colors } = useLogic({
		data,
		xDataKey,
		yDataKeys,
		xDataType,
		chartType,
		uniqueXValues
	});
	const [filteredData, setFilteredData] = useState<DataItem[]>(data);
	const [visibleColors, setVisibleColors] = useState<{ original: string, hover: string }[]>(colors);
	const isPie = chartType === "pie";

	const keysToCheck = useMemo(() =>
		isPie ? uniqueXValues : yDataKeys as string[],
		[isPie, uniqueXValues, yDataKeys]);
	const [visibleKeys, setVisibleKeys] = useState<string[]>([...keysToCheck] as string[]);

	useEffect(() => {
		setVisibleKeys([...keysToCheck] as string[]);
	}, [keysToCheck])


	useEffect(() => {
		const newFilteredData = isPie ? data.map((item) => {
			if (!visibleKeys.includes(item.label.toString())) {
				return null;
			}
			return item;
		}).filter(d => d !== null) : data.map((item) => {
			const newItem = { ...item };
			keysToCheck.forEach((key: string) => {
				if (!visibleKeys.includes(key)) {
					delete newItem[key];
				}
			});
			return newItem;
		}).filter(d => d !== null);
		const newVisibleColors = colors.filter((color, index) => visibleKeys.includes(keysToCheck[index] as string));
		setVisibleColors(newVisibleColors);
		setFilteredData(newFilteredData);
	}, [visibleKeys, data, yDataKeys]);

	const toggleVisibility = (key: string) => {
		if (visibleKeys.includes(key)) {
			// If key exists, remove it
			setVisibleKeys(visibleKeys.filter((k) => k !== key));
		} else {

			// Find all keys from yDataKeys that are in visibleKeys or are the key we're adding
			const orderedKeys = keysToCheck.filter((k: string) => visibleKeys.includes(k) || k === key);

			// Set the visibleKeys in the original order
			setVisibleKeys(orderedKeys as string[]);
		}
	};
	// Calculate tick rotation based on formatted value length
	const getTickRotation = (value: any) => {
		const formattedValue = xDataType === "datetime" ? timeFormat("%B %d, %Y %H:%M")(value) : value;
		const length = formattedValue?.toString().length;
		if (length > 20 && !isPreview) return -30;
		if (length > 20 && isPreview) return -80;
		if (length >= 10) return -45;
		return 0;
	};


	const renderChart = () => {
		const globalMaxYValue = Math.max(
			...data.flatMap((item) =>
				yDataKeys.map((key) => item[key] as number)
			)
		);
		switch (chartType) {
			case "bar": {
				// Get sample value to determine rotation
				const sampleValue = data.reduce((max, item) => {
					const currentValue = item[xDataKey];
					return currentValue?.toString().length > max?.toString().length ? currentValue : max;
				}, data[0][xDataKey]);
				const tickRotation = getTickRotation(sampleValue);

				return (
					<ResponsiveBar
						animate
						data={filteredData as BarDatum[]}
						keys={visibleKeys as string[]}
						indexBy={xDataKey as string}
						enableLabel={false}
						enableGridX={false}
						enableGridY={false}
						padding={0.3}
						innerPadding={0}
						valueScale={{ type: "linear" }}
						margin={{ top: 5, right: 5, bottom: tickRotation < 0 ? 120 : 95, left: 75 }}
						colors={visibleColors.map((color) => color.original)}
						axisBottom={{
							format: (value) => xDataType === "datetime"
								? timeFormat("%B %d, %Y %H:%M")(value)
								: value,
							tickSize: 0,
							tickPadding: 10,
							tickRotation,
							legend: "",
							legendPosition: "middle",
							legendOffset: 32
						}}
						axisLeft={{
							legend: yDataKeys
								.join(", ")
								.split("_")
								.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
								.join(" "),
							legendPosition: "middle",
							legendOffset: -40,
							tickValues: 4,
							tickSize: 0,
							tickPadding: 12,
						}}
						theme={{
							textColor: "#908E9A",
							fontSize: 10,
							grid: {
								line: {
									stroke: "#E8E7ED",
									strokeWidth: 1
								}
							}
						}}
						tooltip={CustomBarTooltip}
					/>
				);
			}
			case "line": {
				const series = visibleKeys.map((key) => ({
					id: key,
					data: data.map((item) => ({
						x: item[xDataKey] as string | number | Date,
						y: item[key] as number
					}))
				}));

				// Get sample value to determine rotation
				const sampleValue = data.reduce((max, item) => {
					const currentValue = item[xDataKey];
					return currentValue?.toString().length > max?.toString().length ? currentValue : max;
				}, data[0][xDataKey]);
				const tickRotation = getTickRotation(sampleValue);

				return (
					<>
						<ResponsiveLine
							animate
							useMesh
							enableArea
							curve="monotoneX"
							data={series as Serie[]}
							enableGridX={false}
							enableGridY={false}
							yScale={{
								type: 'linear',
								min: 0,
								max: globalMaxYValue,
								stacked: false,
							}}
							margin={{ top: 5, right: 5, bottom: tickRotation < 0 ? 120 : 95, left: 75 }}
							colors={visibleColors.map((color) => color.original)}
							axisBottom={{
								format: (value: any) => (xDataType === "datetime" ? timeFormat("%B %d, %Y %H:%M")(value) : value),
								tickSize: 0,
								tickPadding: 10,
								tickRotation,
								legend: "",
								legendPosition: "middle",
								legendOffset: 32
							}}
							axisLeft={{
								legend: yDataKeys
									.join(", ")
									.split("_")
									.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
									.join(" "),
								legendPosition: "middle",
								legendOffset: -40,
								tickValues: 4,
								tickSize: 0,
								tickPadding: 12,
							}}
							theme={{
								textColor: "#908E9A",
								fontSize: 10,
								grid: {
									line: {
										stroke: "#E8E7ED",
										strokeWidth: 1
									}
								}
							}}
							defs={[
								{
									colors: [
										{
											color: "inherit",
											offset: 0
										},
										{
											color: "inherit",
											offset: 100,
											opacity: 0
										}
									],
									id: "gradientA",
									type: "linearGradient"
								}
							]}
							fill={[
								{
									id: "gradientA",
									match: "*"
								}
							]}
							enableSlices="x"
							sliceTooltip={CustomTooltip}
						/>
						<Tooltip />
					</>
				);
			}
			case "pie": {

				return (
					<PieChartWrapper>
						<ResponsivePie
							data={filteredData}
							margin={{ top: 20, right: 50, bottom: 40, left: 50 }}
							innerRadius={0.5}
							padAngle={0.7}
							cornerRadius={3}
							borderWidth={1}
							enableArcLinkLabels={false}
							arcLinkLabelsColor={{ from: 'color' }}
							colors={visibleColors.map((color) => color.original)}
						/>
					</PieChartWrapper>
				);
			}

			default: {
				return null;
			}
		}
	};

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<ChartWrapper height={height}>{renderChart()}</ChartWrapper>
			{showLegend && !isPie && (
				<Legend>
					{yDataKeys.map((el: string, index: number) => (
						<ValueItem key={index} onClick={() => toggleVisibility(el)} $active={visibleKeys.includes(el)}>
							<ColorLabel style={{ backgroundColor: colors[index]?.original }} />{" "}
							{el
								.split("_")
								.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
								.join(" ")}
						</ValueItem>
					))}
				</Legend>
			)}
			{showLegend && isPie && (
				<Legend>
					{data.map((item, index: number) => (
						<ValueItem key={index} onClick={() => toggleVisibility(item.label.toString())} $active={visibleKeys.includes(item.label.toString())}>
							<ColorLabel style={{ backgroundColor: colors[index]?.original }} />{" "}
							{item.label.toString()
								.split("_")
								.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
								.join(" ")}
						</ValueItem>
					))}
				</Legend>
			)}
		</ThemeProvider>)




}
