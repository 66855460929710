import Button from "../Button";
import IconButton from "../IconButton";
import InputSearch from "../InputSearch";
import DefaultPagination from "../Pagination";
import Tag from "../Tag";
import { Caption, Small } from "../Typography";
import { prettyScrollbar } from "../../styles/mixins";
import styled from "styled-components";
import SelectCheckboxesSearch from "../SelectCheckboxesSearch";

export const Container = styled.div`
	width: 100%;
	// min-width: 50.25rem;
	background: ${({ theme }) => theme.colors.white} 0% 0% no-repeat padding-box;
	box-shadow: ${({ theme }) => theme.shadows.shadow12};
	border-radius: 0.5rem;
	padding-block-start: 0.5rem;
`;

export const Content = styled.div`
	${prettyScrollbar({ vertical: false })}
	overflow-x: auto;
	height: 78vh;
`;

export const Header = styled.div`
	display: flex;
	gap: 0.75rem;
	align-items: center;
	justify-content: start;
	margin-block-end: 2rem;
	padding-inline: 1.5rem;
	margin-top: 2rem;
`;

export const Search = styled(InputSearch)`
	min-width: 8rem;
`;

export const Filter = styled.div`
	width: 100%;
	max-width: 12.25rem;
	height: 2.25rem;
	border: 1px solid ${({ theme }) => theme.colors.neutral300};
	border-radius: 0.5rem;
`;

export const AllFiltersButton = styled(Button)`
	flex-shrink: 0;
`;

export const ClearAllButton = styled(Button)`
	flex-shrink: 0;
	padding-inline: 0;
`;

export const MainActionButton = styled(Button)`
	flex-shrink: 0;
`;

export const DownloadButton = styled(IconButton)``;

export const SelectedFilters = styled.div`
	padding-inline: 1.5rem;
	display: flex;
	gap: 0.5rem;
	align-items: center;
	margin-block-end: 1.5rem;
`;

export const NoData = styled.div`
	min-height: 30px;
`;

export const TableContainer = styled.table`
	width: 12.25rem;
	table-layout: fixed;
	border-collapse: collapse;
	border-spacing: 0;
`;

export const TableHeader = styled.thead``;

export const TableHeaderRow = styled.tr``;

export const TableBody = styled.tbody``;

export const Text = styled(Caption)``;

export const CenterText = styled(Caption)`
	left: 47%;
	position: absolute;
	overflow: hidden;
	line-height: 25rem;
`;

export const HeaderCell = styled.th`
	padding-inline: 0;
	padding-block: 0.5rem 1rem;
	text-align: start;
`;

export const ItemNameHeaderCell = styled(HeaderCell)<{ width: number }>`
	width: ${(p) => (p.width ? p.width : 8.375)}rem;
	padding-inline-start: 1rem;
	padding-bottom: 0.5rem;
`;

export const ItemNameSearchCell = styled(HeaderCell)<{ width: number }>`
	width: ${(p) => (p.width ? p.width : 8.375)}rem;
	padding-inline-start: 1rem;
	padding-top: 0;
`;

export const Wrapper = styled.div`
	display: flex;
	gap: 0.375rem;
	align-items: center;
	> svg {
		color: ${({ theme }) => theme.colors.neutral600};
	}
`;

export const SecondaryInfoHeaderCell = styled(HeaderCell)`
	width: 8.375rem;
`;

export const ActionHeaderCell = styled(HeaderCell)<{ width: number }>`
	width: ${(p) => (p.width ? p.width : 8.375)}rem;
	padding-inline-start: 1rem;
`;

export const HelpHeaderCell = styled(HeaderCell)`
	width: 4.375rem;
`;

export const IconHeaderCell = styled(HeaderCell)`
	width: 4.375rem;
	text-align: center;
`;

export const DateHeaderCell = styled(HeaderCell)`
	width: 10.5625rem;
	text-align: end;
`;
export const SettingsHeaderCell = styled(HeaderCell)`
	width: 3rem;
	text-align: end;
	padding-inline-end: 1rem;
`;

export const FilterText = styled(Small)`
	color: ${({ theme }) => theme.colors.neutral600};
`;

export const RowsWrapper = styled.div``;

export const Filters = styled(Tag)`
	gap: 0.5rem;
`;

export const NavigationWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	padding-inline: 2rem;
	padding: 0.5rem;
	@media (max-width: 800px) {
		flex-direction: column;
	}
`;

export const Pagination = styled(DefaultPagination)``;

export const ColumnChooser = styled(SelectCheckboxesSearch)`
	min-height: inherit;
	min-width: 300px;
	max-width: 300px;
`;
