import { ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import { Container } from "@material-ui/core";
import GlobalStyle from "../../styles/global";
import { AppThemes } from "../../roambee-client-styleguide";
import { Title } from "../Typography";
import { CloseButton, CloseIconWrapper, Content, ContentHeader, ContentWrapper, RightPanel } from "./styles";

type DrawerProps = {
	children: ReactNode;
	open: boolean;
	setOpen: (open: boolean) => void;
	title: string;
	width?: number;
	setRowSelection?: (selection: any) => void; // Made setRowSelection optional
	onClose?: () => void;
};

export default function DrawerPanel({ children, open, setOpen, title, width, setRowSelection, onClose }: DrawerProps): JSX.Element {
	const toggleDrawer = () => {
		setOpen(!open);
		onClose?.();
		setRowSelection && setRowSelection({});
	};

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container>
				<RightPanel variant="persistent" anchor="right" open={open} width={width}>
					<ContentWrapper>
						<ContentHeader>
							<Title $bold>{title}</Title>
							<CloseIconWrapper>
								<CloseButton onClick={toggleDrawer} size={20} />
							</CloseIconWrapper>
						</ContentHeader>
						<Content>{children}</Content>
					</ContentWrapper>
				</RightPanel>
			</Container>
		</ThemeProvider>
	);
}
