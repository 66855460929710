import light from "../../styles/themes/light";
import { ChartProps, DataItem } from "./types";

export default function useLogic<T extends DataItem>({ data, chartType, uniqueXValues }: ChartProps<T>) {
	const dataItems = data[0];
	const dataKeys: string[] = dataItems ? Object.keys(dataItems).slice(1) : [];

	const colors = [
		{
			original: light.colors.yellowBee200,
			hover: light.colors.yellowBee400
		},
		{
			original: light.colors.purpleSunset200,
			hover: light.colors.purpleSunset600
		},
		{
			original: light.colors.oceanicBlue200,
			hover: light.colors.oceanicBlue400
		},
		{
			original: light.colors.supportSuccess200,
			hover: light.colors.supportSuccess400
		},
		{
			original: light.colors.supportWarning200,
			hover: light.colors.supportWarning400
		},
		{
			original: light.colors.supportError200,
			hover: light.colors.supportError400
		}
	];

	const getRepeatedColors = (length: number) => {
		const repeatedColors = [];
		for (let i = 0; i < length; i++) {
			repeatedColors.push(colors[i % colors.length]);
		}
		return repeatedColors;
	};

	return {
		dataKeys,
		colors: getRepeatedColors(chartType === "pie" ? uniqueXValues.length : dataKeys.length)
	};
}
