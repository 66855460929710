import StyledDropdownButton from "../DropdownButton";
import { Button, DropdownWrapper } from "../DropdownButton/styles";
import styled from "styled-components";
import { Tabs as MuiTabs } from "@mui/material";

export const StyledMuiTabs = styled(MuiTabs)`
	width: 100%;
	align-content: center;
	&&& {
		overflow: visible;
	}
	.MuiTabs-root {
		overflow: visible;
	}
	.MuiTabScrollButton-root:first-of-type {
		margin-left: -40px;
	}
	.MuiTabScrollButton-root:last-of-type {
		margin-right: -40px;
	}
	.MuiTabs-scroller {
		align-content: center;
		overflow: scroll;
	}
	.MuiTabs-indicator {
		background-color: black;
	}
`;

export const TabsLi = styled.li`
	width: auto;

	& + & {
		margin-inline-start: 1.125rem;
	}
`;

export const IconContainer = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
`;

export const DropdownButton = styled(StyledDropdownButton)`
	width: fit-content;
	height: fit-content;

	${Button} {
		padding-inline: 0.5rem;
		padding-block: 0.5rem;
	}

	${DropdownWrapper} {
		min-width: 9.1875rem;
	}
`;
