import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import { AutocompleteElement, Chevron, Container, Dropdown, Input, Label } from "./styles";
import type { SelectCategoryProps } from "./types";
import Tag from "../Tag";

export default function SelectCategory({
	className,
	label,
	id,
	selectOptions,
	disabled,
	categories,
	showCategoryTag = false,
	value,
	onChange
}: SelectCategoryProps): JSX.Element {
	const options = selectOptions.map((option) => {
		if (categories) {
			// Find which category this option belongs to
			for (const [category, items] of Object.entries(categories)) {
				if ((items as string[]).includes(option)) {
					return {
						category,
						option
					};
				}
			}
		}
		// Fallback to default category based on first letter
		const firstLetter = option.charAt(0).toUpperCase();
		return {
			category: `Category ${firstLetter}`,
			option
		};
	});

	// Find the selected option object based on value prop
	const selectedOption = value ? options.find(opt => opt.option === value) : null;

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				{label && (
					<Label $bold as="label" htmlFor={id} $isDisabled={disabled}>
						{label}
					</Label>
				)}
				<AutocompleteElement
					disableClearable
					disabled={disabled}
					$isDisabled={disabled}
					id={id}
					value={selectedOption}
					onChange={(event, newValue) => {
						onChange?.(newValue && typeof newValue === 'object' && 'option' in newValue ? (newValue.option as string) : null);
					}}
					popupIcon={<Chevron />}
					noOptionsText={"No options available"}
					renderInput={(params) => (
						<Input
							placeholder="Select"
							{...params}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<>
										{showCategoryTag && params.inputProps?.['value'] && (
											<Tag
												label={options.find(opt => opt.option === params.inputProps?.['value'])?.category}
												onClose={function noRefCheck() { }}
											/>
										)}
										{params.InputProps.endAdornment}
									</>
								)
							}}
						/>
					)}
					PaperComponent={({ children }) => <Dropdown>{children}</Dropdown>}
					options={options.sort((a, b) => -b.category.localeCompare(a.category))}
					groupBy={(option) =>
						(
							option as {
								category: string;
								option: string;
							}
						).category
					}
					getOptionLabel={(option) => {
						const typedOption = option as {
							category: string;
							option: string;
						};
						return typedOption.option;
					}}
				/>
			</Container>
		</ThemeProvider>
	);
}
