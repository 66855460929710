import React, { useEffect, useRef, useState } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { useMaterialReactTable, MaterialReactTable, type MRT_Icons, getMRT_RowSelectionHandler } from "material-react-table";
import { Container, Content, Header, MainActions, MainTitle, TableContainer, ColumnVisibilityMenuStyle, FilterActions, FilterActionButton, Divider, StyledList, PopoverContent, Toolbar, StyledCheckbox, ToolTip } from "./styles";
import InputText from "../InputText";
import { ListItem, ListItemText, Popover } from "@mui/material";
import Button from "../Button";
import { Add, Checkbox, CheckboxCheckedFilled, Search, Draggable, ArrowDown, PinFilled, SettingsAdjust } from "@carbon/icons-react";
import SelectSearch from "../SelectSearch";
import IconButton from "../IconButton";
import SelectCheckboxesSearch from "../SelectCheckboxesSearch";

const icon = <Checkbox size={24} />;
const checkedIcon = <CheckboxCheckedFilled size={24} />;

const MoreDropdown = ({ options, selectedFilters, onSelectionChange }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedOptions, setSelectedOptions] = useState([]);

	useEffect(() => {
		setSelectedOptions(selectedFilters);
	}, [selectedFilters]);

	// Open popover
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	// Close popover
	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "more-filters-popover" : undefined;

	// Filter options based on search input
	// const filteredOptions = options.filter((option) => option.header.toLowerCase().includes(searchTerm.toLowerCase()));
	// Handle checkbox selection
	const handleToggleOption = (selectedOption) => {
		console.info("handleToggleOption : selectedOption", selectedOption);
		// Check if the option is already selected
		const isAlreadySelected = selectedOptions.some((option) => option.accessorKey === selectedOption.accessorKey);

		let newSelectedOptions;
		if (isAlreadySelected) {
			// Remove the option if already selected
			newSelectedOptions = selectedOptions.filter((option) => option.accessorKey !== selectedOption.accessorKey);
		} else {
			// Add the option if not selected
			newSelectedOptions = [...selectedOptions, selectedOption];
		}

		// Update selected options state
		setSelectedOptions(newSelectedOptions);

		// Pass the entire selected options array to the parent
		onSelectionChange(newSelectedOptions);

		// Close popover after selection
		handleClose(); // Close popover here
	};

	return (
		<>
			{/* More button */}
			<Button className="more-filters-button" onClick={handleClick} label={"More"} icon={<Add />} variant="outlined" size="small" />

			{/* Popover */}
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left"
				}}
			>
				<PopoverContent>
					{/* Search input */}
					<InputText defaultValue={searchTerm} placeholder="Search" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)} disabled={false} error={false} help={false} id={"more-search-input"} name={"more-search-input"} icon={<Search />} />

					{/* Options list with checkboxes */}
					<StyledList>
						{options.length > 0 ? (
							options.map((option) => (
								<ListItem key={option.accessorKey} onClick={() => handleToggleOption(option)}>
									<StyledCheckbox icon={icon} checkedIcon={checkedIcon} checked={selectedOptions.some((selected) => selected.accessorKey === option.accessorKey)} tabIndex={-1} disableRipple />
									<ListItemText primary={option.header} />
								</ListItem>
							))
						) : (
							<ListItem>
								<ListItemText primary="No options found" />
							</ListItem>
						)}
					</StyledList>
				</PopoverContent>
			</Popover>
		</>
	);
};

export default function DataTable({ className, title, columns, data, rowCount, isLoading, columnFilters, onColumnFiltersChange, columnFilterFns, onColumnFilterFnsChange, sorting, onSortingChange, pagination, onPaginationChange, onColumnPinningChange, columnPinning, columnSizing, onColumnSizingChange, onColumnOrderChange, columnOrder, onDensityChange, density, columnVisibility, onColumnVisibilityChange, headerButtonConfigs = [], systemFilters, onSystemFilters, selectedSystemFilter, defaultFilters, selectedDefaultFilter, onAllFilters, onSaveFilters, onDefaultFilters, setSelectedSystemFilter, onRowClick }): JSX.Element {
	const [selectedFilters, setSelectedFilters] = useState<any[]>([]);
	const filterRefs = useRef([]); // Reference array to handle multiple filters

	// render one time
	useEffect(() => {
		// set selectedFilters to columns, where columnFilter.accessorKey is the same as the column.accessorKey and set value to columnFilter.value
		// filter out columnFilters by finding columnFilter.id in selectedSystemFilter.filterJson.filters
		// if entire columnFilter.id and value is in selectedSystemFilter.filterJson.filters, then don't add it to initialSelectedFilters
		const initialSelectedFilters = columns
			.filter((column) => columnFilters.some((columnFilter) => columnFilter.id === column.accessorKey))
			.filter((column) => !selectedSystemFilter?.filterJson?.filters.some((filter) => filter.field === column.accessorKey && JSON.stringify(filter.value) === JSON.stringify(columnFilters.find((columnFilter) => columnFilter.id === column.accessorKey).value)))
			.map((column) => {
				const value = columnFilters.find((columnFilter) => columnFilter.id === column.accessorKey).value;

				// if value is an array, then return an array of objects with label and value
				// sometimes filterVariant is multi-select or select, but value is not an array, then make it an array, and then return an array of objects with label and value
				if (column.filterVariant === "multi-select") {
					let valueArray = [];
					valueArray = Array.isArray(value) ? value : [value];
					valueArray = valueArray.map((v) => ({ label: v, value: v }));
					return {
						...column,
						value: valueArray
					};
				} else if (column.filterVariant === "select") {
					return {
						...column,
						value: { label: value, value: value }
					};
				} else {
					return {
						...column,
						value: value
					};
				}
			});
		setSelectedFilters(initialSelectedFilters);
	}, [selectedSystemFilter]);

	const renderFilterInput = (filter, ref) => {
		const handleValueChange = (filter, value) => {
			console.info(`Filter ${filter.accessorKey} changed:`, value);

			filter.value = value;

			let filterValue;

			if (value) {
				// if the filter is a multi-select, then return an array of values
				if (filter.filterVariant === "multi-select") {
					filterValue = value.map((v) => v.value);
				} else if (filter.filterVariant === "select") {
					filterValue = [value.value];
				} else {
					filterValue = value;
				}

				// add the filter to the columnFilter
				// remove duplicate filters
			}
			const uniqueFilters = columnFilters.filter((_filter) => _filter.id !== filter.id);
			onColumnFiltersChange([...uniqueFilters, { id: filter.accessorKey, value: filterValue }]);
		};

		if (filter.isSearchable) {
			let filterOptions = [];
			// filter.filterVariant can be "select", "multi-select", or "input"
			if (filter.filterVariant === "select" || filter.filterVariant === "multi-select") {
				filterOptions = filter.filterSelectOptions.map((f) => {
					return { label: f, value: f };
				});
			}

			// filter.filterVariant can be "select", "multi-select", or "input"
			switch (filter.filterVariant) {
				case "select":
					return <SelectSearch id={filter.accessorKey} placeholder={`Search ${filter.header}`} options={filterOptions} disabled={false} value={filter.value} handleValueChange={(event, newValue) => handleValueChange(filter, newValue)} elementRef={ref} />;
				case "multi-select":
					return (
						<SelectCheckboxesSearch
							id={filter.accessorKey}
							placeholder={`Search ${filter.header}`}
							options={filterOptions}
							disabled={false}
							value={filter.value || []}
							onChange={(event, newValue) => {
								filter.value = newValue;
								handleValueChange(filter, newValue);
							}}
							name={""}
							elementRef={ref}
						/>
					);
				case "input":
				default:
					return <InputText id={filter.accessorKey} name={filter.accessorKey} defaultValue={filter.value} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleValueChange(filter, e.target.value)} placeholder={`Search ${filter.header}`} disabled={false} error={false} help={false} debounceTime={300} size="small" elementRef={ref} />;
			}
		} else {
			("");
		}
	};

	const handleMoreSelection = (newSelectedFilters) => {
		console.info("I should be called when more is clicked", newSelectedFilters);
		setSelectedFilters(newSelectedFilters);

		// Auto-focus on the last added filter
		setTimeout(() => {
			const lastFilterRef = filterRefs.current[newSelectedFilters.length - 1];
			if (lastFilterRef && typeof lastFilterRef.focus === "function") {
				lastFilterRef.focus(); // Focus the last added filter input
			}
		}, 500);
	};

	// at initial load, set the selected filters to the column filters where the id of columnFilter is the same as the accessorKey of the column
	// const initialSelectedFilters = columns.filter((column) => column.accessorKey === columnFilters.id);
	// setSelectedFilters(initialSelectedFilters);
	// handleMoreSelection(initialSelectedFilters);

	const icons: Partial<MRT_Icons> = {
		DragHandleIcon: () => <Draggable />,
		SortIcon: (props) => (
			<ArrowDown style={{ transform: "rotate(360deg)" }} {...props} /> //props so that style rotation transforms are applied
		),
		PushPinIcon: () => (
			<PinFilled /> //props so that style rotation transforms are applied
		),
		ViewColumnIcon: () => <SettingsAdjust />
	};

	const table = useMaterialReactTable({
		columns,
		data,
		muiTableBodyRowProps: ({ row }) => ({
			onClick: () => onRowClick(row),
			sx: { cursor: "pointer" }
		}),
		initialState: { showColumnFilters: false, columnPinning: columnPinning },

		rowCount: rowCount,
		// enableColumnFilterModes: true,
		enableColumnFilters: false,
		enableColumnResizing: true,
		enableStickyHeader: true,
		enableStickyFooter: true,
		enableColumnPinning: true,
		enableColumnOrdering: true,
		// enableFacetedValues: true,
		enableGlobalFilter: false,
		manualFiltering: true,
		manualPagination: true,
		manualSorting: true,
		onColumnFiltersChange,
		onColumnFilterFnsChange,
		onPaginationChange,
		onColumnPinningChange: (e) => onColumnPinningChange(e),
		onColumnSizingChange,
		onDensityChange,
		onColumnVisibilityChange,
		onSortingChange,
		onColumnOrderChange,
		paginationDisplayMode: "pages",
		icons,
		state: { isLoading, columnFilters, columnFilterFns, pagination, sorting, columnPinning, columnSizing, columnOrder, density, columnVisibility },
		renderTopToolbarCustomActions: ({ table }) => (
			<MainActions>
				<FilterActions>
					{/* <SearchInputText id="table-search" placeholder="Search by [bee name], [imei], [status]..." data={["Bee1", "Bee2", "Bee3"]} /> */}
					{systemFilters && systemFilters.length > 0 && <SelectSearch id="system-filter" placeholder="Saved Filters" options={systemFilters} disabled={false} value={selectedSystemFilter} handleValueChange={onSystemFilters} />}
					{defaultFilters && defaultFilters.length > 0 && defaultFilters.map((filter) => <SelectSearch id={filter.label} placeholder={filter.label} options={filter.options} disabled={false} handleValueChange={onDefaultFilters} value={selectedDefaultFilter} />)}
					{selectedFilters.map((filter, index) => (
						<div key={filter.accessorKey}>{renderFilterInput(filter, (ref) => (filterRefs.current[index] = ref))}</div>
					))}
					<MoreDropdown options={columns.filter((c) => c.isSearchable)} selectedFilters={selectedFilters} onSelectionChange={handleMoreSelection} />
					<Divider />
					{/* Add Reset, Save, and All Filters buttons */}
					<FilterActionButton
						label="Reset"
						size="small"
						variant="no-bg"
						disabled={selectedFilters.length === 0}
						onClick={() => {
							// remove selectedFilters from columnFilters
							const updatedColumnFilters = columnFilters.filter((filter) => !selectedFilters.some((selected) => selected.accessorKey === filter.id));
							onColumnFiltersChange(updatedColumnFilters);
							setSelectedFilters([]);
							setSelectedSystemFilter(null);
							localStorage.removeItem("shipment_system_filter");
						}}
					/>
					<FilterActionButton label={selectedSystemFilter ? "Update" : "Save"} size="small" variant="no-bg" disabled={selectedFilters.length === 0} onClick={() => onSaveFilters(selectedFilters)} />
					<FilterActionButton label="All Filters" size="small" variant="no-bg" onClick={onAllFilters} />
				</FilterActions>
			</MainActions>
		)
	});

	const leftPinnedcolumn = columnPinning.left.length - 1;
	const rightPinnedcolumn = columns.length - columnPinning.right.length + 1;
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<ColumnVisibilityMenuStyle />
			<Container className={className}>
				<Content>
					<Header>
						<MainTitle>{title}</MainTitle>
						<Toolbar>
							{headerButtonConfigs.map((headerButtonConfig, index) => (
								<ToolTip title={headerButtonConfig.tooltipText} className="table-actions-ToolTip">
									<div>
										<IconButton key={index} disabled={headerButtonConfig.disabled} size={headerButtonConfig.size} variant={headerButtonConfig.variant} onClick={headerButtonConfig.onClick} icon={headerButtonConfig.icon} ariaLabel={headerButtonConfig.ariaLabel || `Table header action ${index + 1}`} />
									</div>
								</ToolTip>
							))}
						</Toolbar>
					</Header>
					<TableContainer leftPinnedcolumn={leftPinnedcolumn} rightPinnedcolumn={rightPinnedcolumn}>
						<MaterialReactTable table={table} />
					</TableContainer>
				</Content>
			</Container>
		</ThemeProvider>
	);
}
